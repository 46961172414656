import React, { useState, useRef, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { toast } from "react-toastify";

import { Container, FlexGrid } from "@util/standard";
import { colors, colorsRGB } from "@util/constants";
import { Button } from "@global";
import { MailingAddress } from "shopify-storefront-api-typings";
import { PreferencesInput, HandlePreferencesSubmitProps, PreferencesData } from "@util/types";
import { Shopify } from "@state/types";

interface Props {
  label: string;
  defaultValue?: string | null;
  editText?: string;
  editSubmit: (arg0: HandlePreferencesSubmitProps) => void;
  type: PreferencesInput;
  focus: string;
  setFocus: Dispatch<SetStateAction<string>>;
  address?: MailingAddress;
  shopify: Shopify;
};

interface GenerateFieldsProps {
  defaultValue: string;
  type: PreferencesInput;
  handleSubmit: (arg0: HandlePreferencesSubmitProps) => void;
  address?: MailingAddress;
  shopify: Shopify;
  setFocus: React.Dispatch<React.SetStateAction<string>>;
}

interface RegionProps {
  country: string;
  region: string;
}

const Form = styled.form`
  width: 100%;
`;

const CustomerFieldContainer = styled(Container) <{
  focused: boolean;
  blurred: boolean;
}>`
  margin: 0 0 30px;
  padding: 0 0 30px;
  border-bottom: 1px solid ${colorsRGB.merlin(0.2)};

  ${({ focused }) => focused && `
    > div > p{
      color: ${colors.green};
      font-weight: bold;
    }
  ` };
  ${({ blurred }) => blurred && `
    opacity: 0.4;
` };
`;

const Label = styled.p`
  margin: 0;
  color: ${colorsRGB.merlin(0.7)};
`;

const DefaultValue = styled.p`
  margin: 0;
`;

const EditButton = styled.button`
  background: transparent;
  border: none;
  padding: 10px 0;
  transition: all 0.3s ease;
  color: ${colors.green};
  &:hover{
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const Input = styled.input`
  border: none;
  font-size: 1em;
  width: 100%;
  min-height: 1.8em;
  padding: 0.4em 0;
  margin: 0 0 10px;
  border-bottom: 1px solid ${colorsRGB.merlin(0.2)};
  &:focus{
    border-bottom: 1px solid ${colors.green};
  }
`;

const SelectContainer = styled(Container)`
  select{
    border: none;
    font-size: 1em;
    width: 100%;
    min-height: 1.8em;
    padding: 0.4em 0;
    margin: 0 0 10px;
    border-bottom: 1px solid ${colorsRGB.merlin(0.2)};
  }
`;

const formValidation = (type: PreferencesInput, inputArr: Element[]) => {
  let data: PreferencesData = {};
  if (type === "address") {
    data.address = {};
  }
  inputArr.forEach((input) => {
    if (type === "address") {
      data.address[input.name] = input.value;
      return;
    }
    data[input.name] = input.value;
  });

  if (type === "name") {
    if (!data.firstName) {
      toast.error("A first name is required.");
      return false;
    }
    if (!data.lastName) {
      toast.error("A last name is required.");
      return false;
    }
  }

  if (type === "password") {
    if (!data.password) {
      toast.error("Please enter a password.");
      return false;
    }
    if (data.password !== data.confirmPassword) {
      toast.error("The password you have provided does not match.");
      return false;
    }
    if (data.password.length < 6) {
      toast.error("A minimum of 6 characters is required for your password.");
      return false;
    }
    if (!/^[a-zA-Z0-9]*$/.test(data.password)) {
      toast.error("Your password must contain only letters and numbers.");
      return false;
    }
  }

  if (type === "email") {
    if (!data.email) {
      toast.error("Please enter your email address.");
      return false;
    }
    const emailRegex = /^([A-Z|a-z|0-9](\.|_){0,1})+[A-Z|a-z|0-9]\@([A-Z|a-z|0-9])+((\.){0,1}[A-Z|a-z|0-9]){2}\.[a-z]{2,3}$/;
    if (!emailRegex.test(data.email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }
  }

  if (type === "address") {
    if (!data.address?.address1) {
      toast.error("Please enter your address");
      return false;
    }
    if (!data.address?.country) {
      toast.error("Please select your country.");
      return false;
    }
    if (!data.address?.province) {
      toast.error("Please select your region.");
      return false;
    }
    if (!data.address?.zip) {
      toast.error("Please enter your postal code.");
      return false;
    }
  }

  return true;
}

const GenerateFields = ({ defaultValue, type, handleSubmit, address, shopify, setFocus }: GenerateFieldsProps) => {
  const defaultRegion = {
    country: address?.country as string,
    region: address?.province as string
  }
  const [region, setRegion] = useState<RegionProps>(defaultRegion);
  const [adding, setAdding] = useState(false);
  const elForm = useRef<HTMLFormElement>(null);
  const selectCountry = (val: string) => {
    setRegion({ country: val, region: region.region });
  };
  const selectRegion = (val: string) => {
    setRegion({ country: region.country, region: val });
  };

  const submitFunc = async (e: React.FormEvent<HTMLFormElement>) => {
    const submitArg = {
      type: type,
      inputArr: [].slice.call(elForm!.current!.elements).filter((el: Element) => el.localName === "select" || el.localName === "input"),
      shopify: shopify,
      setFocus: setFocus
    };

    e.preventDefault();
    setAdding(true);
    //Form Validation!!!!!!
    const validated = formValidation(type, submitArg.inputArr);

    if (validated === false) {
      setAdding(false);
      return;
    }

    await handleSubmit(submitArg);
    setAdding(false);
  }
  return (
    <Container flexDirection="column" alignItems="flex-start" margin="20px 0 0">
      <Form ref={elForm} onSubmit={submitFunc}>
        <FlexGrid width="100%" gridMargin="20px" margin="0 0 10px">
          {type === "name" &&
            <>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="firstName" placeholder="First Name" />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="lastName" placeholder="Last Name" />
              </Container>
            </>
          }
          {type === "password" &&
            <>
              <Container width="50%" tabletWidth="100%">
                <Input type="password" name="password" placeholder="Password" />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="password" name="confirmPassword" placeholder="Confirm Password" />
              </Container>
            </>
          }
          {type === "email" &&
            <>
              <Container width="100%">
                <Input type="email" name="email" placeholder={defaultValue} />
              </Container>
            </>
          }
          {type === "address" &&
            <>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="firstName" placeholder="First Name" defaultValue={address?.firstName} />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="lastName" placeholder="Last Name" defaultValue={address?.lastName} />
              </Container>
              <Container width="100%">
                <Input type="text" name="company" placeholder="Company" defaultValue={address?.company} />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="address1" placeholder="Address" defaultValue={address?.address1} />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="address2" placeholder="Apartment, suite, etc." defaultValue={address?.address2} />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="city" placeholder="City" defaultValue={address?.city} />
              </Container>
              <Container width="50%" tabletWidth="100%">
                <Input type="text" name="zip" placeholder="Postal Code" defaultValue={address?.zip} />
              </Container>
              <SelectContainer width="50%" tabletWidth="100%">
                <CountryDropdown
                  name="country"
                  value={region.country}
                  onChange={(val) => selectCountry(val)} />
              </SelectContainer>
              <SelectContainer width="50%" tabletWidth="100%">
                <RegionDropdown
                  name="province"
                  country={region.country}
                  value={region.region}
                  onChange={(val) => selectRegion(val)} />
              </SelectContainer>
              <Container width="100%">
                <Input type="text" name="phone" placeholder="Phone" defaultValue={address?.phone} />
              </Container>
            </>
          }
        </FlexGrid>

        <Button disabled={adding} theme="transparent" text={adding ? "ADDING" : "SUBMIT"} />
      </Form>
    </Container>
  );
};

export default function UpdateCustomerField({ label, defaultValue, editText = "Edit", editSubmit, type, focus, setFocus, address, shopify }: Props) {

  return (
    <CustomerFieldContainer focused={focus ? true : false} blurred={focus !== "" && focus !== type} justifyContent="space-between" alignItems="flex-start">
      <Container width="100%" flexDirection="column">
        <Container margin="0 0 12px" justifyContent="space-between" alignItems="center">
          <Label>{label}</Label>
          <Container width="150px" justifyContent="flex-end">
            {focus === type ?
              <EditButton onClick={() => { setFocus(""); }}>Cancel</EditButton>
              :
              <EditButton onClick={() => { setFocus(type); }}>{editText}</EditButton>
            }
          </Container>
        </Container>

        {focus === type ?
          <GenerateFields handleSubmit={editSubmit} type={type} defaultValue={defaultValue as string} address={address} shopify={shopify} setFocus={setFocus} />
          :
          <DefaultValue>{defaultValue}</DefaultValue>
        }
      </Container>
    </CustomerFieldContainer>
  )
}