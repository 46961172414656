import React, { useState } from "react";
import { toast } from "react-toastify";

import { Container, P } from "@util/standard";
import { addressToString } from "@util/helper";
import { Shopify } from "@state/types";
import CustomerField from "@components/account/updateCustomerField"
import { HandlePreferencesSubmitProps, PreferencesData } from "@util/types";
import { Button } from "@global";
import { updateCustomer } from "@util/getFirebase";

interface Props {
  shopify: Shopify;
};

interface PostUpdateProps {
  data: any;
  shopify: Shopify;
  setFocus: React.Dispatch<React.SetStateAction<string>>;
}

const postUpdate = async ({ data, shopify, setFocus }: PostUpdateProps) => {
  const postData = {
    customer: data,
    customerAccessToken: shopify.customer?.token
  }
  if (!shopify.customer?.token) return;
  const res = await fetch(`/api/customerUpdate`, {
    method: "POST",
    body: JSON.stringify(postData),
  });
  try {
    const response = await res.json();
    updateCustomer(response, shopify);
    toast.success("Your details have been updated successfully!");
    if (setFocus) {
      setFocus("");
    }
  } catch (err) {
    throw err;
  }
};

const postAddressUpdate = async ({ data, shopify, setFocus }: PostUpdateProps) => {
  const postData = {
    address: data.address,
    id: shopify.customer?.defaultAddress?.id,
    customerAccessToken: shopify.customer?.token
  }

  console.log("post data: ", postData);

  if (!shopify.customer?.token) return;
  const res = await fetch(`/api/customerAddressUpdate`, {
    method: "POST",
    body: JSON.stringify(postData),
  });

  console.log("address update: ", res);
  try {
    const response = await res.json();
    updateCustomer(response, shopify);
    toast.success("Your address have been updated successfully!");
    if (setFocus) {
      setFocus("");
    }
  } catch (err) {
    throw err;
  }
};

const handleSubmit = async ({ type, inputArr, shopify, setFocus }: HandlePreferencesSubmitProps) => {
  let data: PreferencesData = {};
  if (type === "address") {
    data.address = {};
  }
  inputArr.forEach((input) => {
    if (type === "address") {
      data.address[input.name] = input.value;
      return;
    }
    data[input.name] = input.value;
  });

  if (type === "password") {
    delete data.confirmPassword;
  }

  if (type === "address") {
    await postAddressUpdate({ data, shopify, setFocus });
    return;
  }

  await postUpdate({ data, shopify, setFocus });
  return;
}

const handleSubscribe = (shopify: Shopify, setFocus: React.Dispatch<React.SetStateAction<string>>) => {
  const data = {
    acceptsMarketing: !shopify.customer?.acceptsMarketing
  };
  setFocus("subscribe");
  postUpdate({ data, shopify, setFocus });
};

export default function Orders({ shopify }: Props) {
  const [focus, setFocus] = useState<string>("");

  if (!shopify.customer) {
    return (
      <P color="errorRed">Failed to display order. Please contact us for support.</P>
    );
  }

  return (
    <Container flexDirection="column" width="100%" alignItems="flex-start">
      <Container flexDirection="column" width="100%" alignItems="stretch">
        <CustomerField
          label="Name"
          defaultValue={shopify.customer.displayName}
          editSubmit={handleSubmit}
          type="name"
          focus={focus}
          setFocus={setFocus}
          shopify={shopify}
        />
        <CustomerField
          label="Password"
          defaultValue="*********"
          editText="Change Password"
          editSubmit={handleSubmit}
          type="password"
          focus={focus}
          setFocus={setFocus}
          shopify={shopify}
        />
        <CustomerField
          label="Email Address"
          defaultValue={shopify.customer.email}
          editSubmit={handleSubmit}
          type="email"
          focus={focus}
          setFocus={setFocus}
          shopify={shopify}
        />
        <CustomerField
          label="Address"
          defaultValue={addressToString(shopify.customer.defaultAddress)}
          editSubmit={handleSubmit}
          type="address"
          address={shopify.customer.defaultAddress}
          focus={focus}
          setFocus={setFocus}
          shopify={shopify}
        />
      </Container>
      <Button disabled={focus === "subscribe"} onClick={() => handleSubscribe(shopify, setFocus)} text={shopify.customer?.acceptsMarketing ? "UNSUBSCRIBE" : "SUBSCRIBE"} theme="transparent" />
    </Container >
  );
}